/* eslint-disable max-len */
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Wrapper,
  H2,
  Circle,
} from '../../../components'
import BlockImage from './block-image'

const BlockInfo = ({
  info,
}) => {
  const { t } = useTranslation()
  return (
    <Wrapper
      paddingM="100px 25px 0"
      paddingD="0 15px"
      flexDirectionD="column" >
      <div className="box-circle">
        <Circle
          style={{ transform: 'scaleX(-1)' }}
        />
      </div>
      <Wrapper
        marginM="32px 0px"
        marginD="160px 0 120px"
        widthD="1440px"
      >
        <H2
          style={{
            textAlign: 'center',
          }}
          fontSizeD="4xl"
          color="primary"
          lineHeightD="6xl"
        >
          {_.get(info, 'highlight')}
        </H2>
      </Wrapper>
      <BlockImage
        content={_.get(info, 'our_approach_section')}
        type="approach"
        title={t('server.approach')}/>
      <BlockImage
        content={_.get(info, 'results_section')}
        type="results"
        title={t('server.results')}/>
    </Wrapper>

  )
}

export default BlockInfo
